@import '/styles/variables.scss';
@import '/styles/helpers/responsive.scss';

.head {
  position: relative;
  z-index: 1;
  overflow: hidden;
  background-color: var(--color-primary-700);

  @include tablet {
    border-radius: 0 0 30px 30px;
  }
}

.head_bg {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 0.7;
}

.head_content {
  position: relative;
  z-index: 1;
  padding: #{$header-height + 40} 50px 130px;
  background: linear-gradient(180.05deg, #17183B 0.04%, rgba(23, 24, 59, 0.7) 76.83%);
  color: var(--color-white);

  @include tablet {
    padding: #{$mobile-header-height + 80} 30px 240px;
  }
}

.mainTitle {
  max-width: 640px;
  margin: 0 auto 50px;
  text-align: center;
  font-weight: 400;

  &.with_config_header {
    margin-bottom: 10px;
  }

  @include tablet {
    font-size: var(--fs-16);
    max-width: initial;
    margin: 0 auto;
  }
}

.mobileLogo {
  svg {
    width: 100%;
  }
}

.logo {
  width: 8%;
}

.config_header {
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 5px;
  padding: 15px 20px;
  margin: 20px auto 0;
  text-align: center;
  line-height: 1.2;
  max-width: 630px;

  .icon {
    min-width: 20px;
  }
}