@import "/styles/helpers/responsive.scss";
@import "/styles/variables.scss";
@import "/styles/helpers/mixins.scss";

.input_wrapper {
  position: relative;
}

.input {
  position: relative;
  padding: 8px 20px;
  height: 100%;
  min-width: 250px;
  color: var(--color-black-600);
  cursor: pointer;
  text-align: left;
  transition: all .3s ease-in-out;
  white-space: nowrap;

  @include mobile {
    padding: 8px 15px;
    white-space: normal;
  }

  &.with_small_label {
    padding: 17px 20px 6px;
  }

  &.with_arrow {
    position: relative;

    &::after {
      content: url('data:image/svg+xml; utf8, <svg width="10" height="8" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11 1.5L6 6.5L1 1.5" stroke="%23314554" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/></svg>');
      height: 22px;
      margin-left: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      transform-origin: center;
      transition: transform .2s ease-in-out;
    }

    &.input_selected {
      &::after {
        transform: rotate(180deg) translateY(-3px);
      }
    }
  }
}

.input_picto {
  position: relative;
  display: inline-block;
  width: 20px;
  max-width: 20px;
  min-width: 20px;
  height: 20px;
  margin: 2px 10px 0 0;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity .3s ease-in-out, transform .3s ease-in-out;

    &:first-child {
      opacity: 1;
      transform: rotate(0);
    }
    &:nth-child(2) {
      opacity: 0;
      transform: rotate(0);
    }
  }

  path {
    stroke-width: 1px;
  }

  &.filled {
    color: var(--color-success);

    svg {
      &:first-child {
        opacity: 0;
        transform: rotate(360deg);
      }
      &:nth-child(2) {
        opacity: 1;
        transform: rotate(360deg);
      }
    }
  }
}

.input_label {
  min-width: 160px;
  line-height: 2;
}

.input_selected {
  background-color: var(--color-grey-100);
  color: var(--color-primary-500);
}

.input_value {
  flex: 1 1 auto;
  max-width: calc(100% - 30px);
  color: var(--color-black);
  white-space: nowrap;
  line-height: 2;
  
  .input_value_text {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.small_label {
  position: absolute;
  top: 15px;
  left: 20px;
  font-size: var(--fs-10);
  font-weight: 400;
  color: var(--color-black-600);
  opacity: 0;
  transition: opacity .2s ease-in-out, top .2s ease-in-out;

  &.visible {
    opacity: 1;
    top: 7px;
  }
}

.panel {
  position: absolute;
  z-index: 1;
  top: calc(100% + 5px);
  left: 0;
  min-width: 350px;
  max-height: calc(100vh - 170px);
  overflow: auto;
  background-color: var(--color-white);
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(31, 51, 116, 0.07);
  padding: 20px;

  // closed
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, transform 0.2s ease-in-out;

  // align
  &.right {
    left: initial;
    right: 0;
  }
  &.center {
    left: 50%;
    transform: translateX(-50%) translateY(10px);

    @include mobile {
      transform: translateX(-50%) translateY(100px);
    }
  }

  @include tablet {
    max-width: 100%;
    min-width: 100%;
    width: 100%;
  }

  @include mobile {
    position: fixed;
    z-index: 2;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateY(100px);
    border-radius: 0;
    max-height: 100vh;
    min-height: 100vh;
    min-height: -webkit-fill-available; /* mobile viewport bug fix */
    padding: 0;
    box-shadow: 0 -10px 20px rgba(17, 29, 66, 0.098);
  }
}

.panel_opened {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);

  &.center {
    transform: translateX(-50%) translateY(0);
  }
}

.close_panel {
  position: absolute;
  z-index: 1;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  min-width: 30px;
  height: 30px;
  cursor: pointer;
  background-color: var(--color-white);
  border-radius: 50px;
}

.close_panel_mobile {
  background-color: var(--color-secondary);
  color: var(--color-white);
  width: 34px;
  min-width: 34px;
  height: 34px;
  border-radius: 50%;
}

.next {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  column-gap: 0.65em;
}

.apply_mobile {
  position: sticky;
  bottom: 0;
  width: 100%;
  margin: auto 0 0;
  padding: 19px 20px 20px;
  background-color: var(--color-white);

  @include iphone-toolbar;

  button {
    padding: 18px 20px;
  }
}

.modal_title {
  margin-bottom: 5px;
  padding: 20px 0;
  background-color: var(--color-bg);
  line-height: 1.2;
}